import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getOems} from 'stores/Companies';

export default function usePartsSourceOems() {
    const partsSourceOems = useSelector(state => state.companies.oems);
    const dispatch = useDispatch();

    useEffect(() => {
        if (partsSourceOems.length === 0) {
            dispatch(getOems());
        }
    }, [partsSourceOems.length]);

    return partsSourceOems;
}
